import React from "react";
import styled from "styled-components";

import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { featuresManager, upcomingTransfersManager } from "../../../../shared/core/service/services";
import type { UpcomingTransfer } from "../../../../shared/domains/transactions/upcoming-transfers/upcoming-transfers";
import { UpcomingTransferStatus } from "../../../../shared/domains/transactions/upcoming-transfers/upcoming-transfers";
import useUpcomingTransfers from "../../../../shared/domains/transactions/upcoming-transfers/use-upcoming-transfers";
import { DELETE_UPCOMING_TRANSFER_MODAL_ID, EDIT_UPCOMING_TRANSFER_MODAL_ID } from "../../../core/modal/modal-id";
import { PrimaryButton } from "../../common/buttons/primary-button";
import { Modal } from "../../common/modal/modal";
import { PageHeader } from "../../common/page/page-header";
import { DeleteUpcomingTransferModal } from "./components/delete-upcoming-transfer-modal";
import { EditUpcomingTransferModal } from "./components/edit-upcoming-transfer-modal";
import { UpcomingTransferItem } from "./components/upcoming-transfer-item";

const UpcomingTransferScreen = () => {
  const { formatMessage } = useIntl();
  const { upcomingTransfers, canLoadMore, loadMore, refresh } = useUpcomingTransfers(upcomingTransfersManager);

  const hasStandingOrderUpdateActivated = featuresManager.features.get().paymentStandingOrderUpdate;
  const hasStandingOrderDeleteActivated = featuresManager.features.get().paymentStandingOrderDelete;

  const handleUpcomingTransferStatus = async (transfer: UpcomingTransfer) => {
    const updatedStatus =
      transfer.status === UpcomingTransferStatus.DEACTIVATED
        ? UpcomingTransferStatus.ACTIVATED
        : UpcomingTransferStatus.DEACTIVATED;

    await upcomingTransfersManager.updateStatusUpcomingTransfer(transfer.id, updatedStatus);
    refresh();
  };

  const handleUpcomingTransferDelete = (transfer: UpcomingTransfer) => {
    Modal.present(
      DELETE_UPCOMING_TRANSFER_MODAL_ID,
      () => (
        <DeleteUpcomingTransferModal
          transfer={transfer}
          onConfirm={async () => {
            await upcomingTransfersManager.deleteUpcomingTransfer(transfer.id);
            refresh();
          }}
        />
      ),
      {
        canBeDismissed: true,
      },
    );
  };

  const handleUpcomingTransferEdit = (transfer: UpcomingTransfer) => {
    Modal.present(
      EDIT_UPCOMING_TRANSFER_MODAL_ID,
      () => (
        <EditUpcomingTransferModal
          transfer={transfer}
          onConfirm={async (modifiedTransfer) => {
            await upcomingTransfersManager.editUpcomingTransfer(transfer.id, modifiedTransfer);
            refresh();
          }}
        />
      ),
      {
        canBeDismissed: false,
      },
    );
  };

  return (
    <>
      <PageHeader
        title={formatMessage("transfertsSubmenu.upcomingTransfers")}
        description={formatMessage("upcomingTransfers.description")}
      />
      <UpcomingTransfersWrapper>
        {upcomingTransfers.map((transfer) => (
          <UpcomingTransferItem
            key={transfer.id}
            transfer={transfer}
            hasStandingOrderUpdateActivated={hasStandingOrderUpdateActivated}
            hasStandingOrderDeleteActivated={hasStandingOrderDeleteActivated}
            handleUpcomingTransferDelete={handleUpcomingTransferDelete}
            handleUpcomingTransferStatus={handleUpcomingTransferStatus}
            handleUpcomingTransferEdit={handleUpcomingTransferEdit}
          />
        ))}
      </UpcomingTransfersWrapper>
      {canLoadMore && (
        <LoadMore>
          <PrimaryButton size="S" onClick={loadMore}>
            {formatMessage("upcomingTransfers.loadMore")}
          </PrimaryButton>
        </LoadMore>
      )}
    </>
  );
};

const UpcomingTransfersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LoadMore = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
`;

export default UpcomingTransferScreen;
