import React, { useState } from "react";
import styled from "styled-components";

import type { Amount } from "../../../../../shared/core/amount/amount";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { RECURRING_TRANSFER_FREQUENCY_OPTIONS } from "../../../../../shared/domains/transactions/recurring-transfer";
import type { UpcomingTransfer } from "../../../../../shared/domains/transactions/upcoming-transfers/upcoming-transfers";
import { EDIT_UPCOMING_TRANSFER_MODAL_ID } from "../../../../core/modal/modal-id";
import { PrimaryButton } from "../../../common/buttons/primary-button";
import { AmountInput } from "../../../common/forms/amount-input";
import FormLabel from "../../../common/forms/form-label";
import { SelectInput } from "../../../common/forms/select-input";
import { TextInput } from "../../../common/forms/text-input";
import { Modal } from "../../../common/modal/modal";
import { RoundedModalContainer } from "../../../common/modal/rounded-modal-container";
import { theme } from "../../../styles/theme";

export interface EditUpcomingTransferModalProps {
  transfer: UpcomingTransfer;
  onConfirm: (modifiedTransfer: UpcomingTransfer) => void;
}

export const EditUpcomingTransferModal = ({ transfer, onConfirm }: EditUpcomingTransferModalProps) => {
  const { formatMessage } = useIntl();

  const {
    paymentTransaction: {
      paymentTransactionAmountInformation,
      paymentTransactionDedicatedInformations: { remittanceInformation } = {},
    },
    firstExecutionDate,
    frequency: utFrequency,
    endDate: utEndDate,
  } = transfer;

  const utFrequencyOption = RECURRING_TRANSFER_FREQUENCY_OPTIONS.find(
    (freqOpt) => freqOpt.frequency.interval === utFrequency.interval && freqOpt.frequency.unit === utFrequency.unit,
  );

  /** Form field values and handlers */
  const [amount, setAmount] = useState<Amount>(paymentTransactionAmountInformation.instructedAmount);
  const [message, setMessage] = useState(remittanceInformation?.value ?? "");
  const [startDate, setStartDate] = useState(firstExecutionDate);
  const [frequency, setFrequency] = useState(utFrequencyOption);
  const [endDate, setEndDate] = useState(utEndDate);

  const closeModal = () => {
    Modal.dismiss(EDIT_UPCOMING_TRANSFER_MODAL_ID);
  };

  const handleConfirm = () => {
    const modifiedTransfer = {
      endDate,
      firstExecutionDate: startDate,
      frequency: frequency?.frequency,
      paymentTransaction: {
        paymentTransactionAmountInformation: {
          instructedAmount: amount,
        },
        paymentTransactionDedicatedInformations: {
          remittanceInformation: {
            value: message,
          },
        },
      },
    };

    onConfirm(modifiedTransfer as UpcomingTransfer);
    closeModal();
  };

  return (
    <ModalContainer closeButton id={EDIT_UPCOMING_TRANSFER_MODAL_ID}>
      <Heading>{formatMessage("upcomingTransfers.editTransfer")}</Heading>
      <Container>
        <FormLabel size="normal" margin="normal" label="upcomingTransfers.scheduledAmount" htmlFor="scheduledAmount" />
        <AmountInput variant="big" value={amount} onChange={setAmount} id="scheduledAmount" />

        <FormLabel size="normal" margin="normal" label="upcomingTransfers.message" htmlFor="message" />
        <TextInput value={message} onChange={(event) => setMessage(event.target.value)} id="message" />

        <FormLabel label="upcomingTransfers.startDate" size="normal" margin="normal" htmlFor="startDate" />
        <TextInput id="startDate" value={startDate} type="date" onChange={(date) => setStartDate(date.target.value)} />

        <FormLabel label="upcomingTransfers.frequency" size="normal" margin="normal" htmlFor="frequency" />
        <SelectInput
          innerId="frequency"
          value={frequency}
          options={RECURRING_TRANSFER_FREQUENCY_OPTIONS}
          itemRenderer={(v) => formatMessage(v!.label)}
          onChange={setFrequency}
        />

        <FormLabel label="upcomingTransfers.endDate" size="normal" margin="normal" htmlFor="endDate" />
        <TextInput id="endDate" value={endDate} type="date" onChange={(date) => setEndDate(date.target.value)} />

        <ConfirmButton size="M" onClick={handleConfirm}>
          {formatMessage("upcomingTransfers.confirmButton")}
        </ConfirmButton>
      </Container>
    </ModalContainer>
  );
};

const ModalContainer = styled(RoundedModalContainer)`
  margin-top: 5vh;
  padding: 20px 40px;
`;

const Heading = styled.div`
  ${theme.boldText};
  font-size: 1.375rem;
  margin-top: 30px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ConfirmButton = styled(PrimaryButton)`
  margin-top: 40px;
  margin-bottom: 30px;
  align-self: center;
`;
