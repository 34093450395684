import React from "react";
import type { RouteProps } from "react-router-dom";
import { Redirect, Route } from "react-router-dom";

import { authenticationManager } from "../../../../shared/core/service/services";
import { useObservable } from "../../../../shared/utils/observable";
import { PageWithNav } from "../../../ui/common/page/page-with-nav";
import { Path, TRANSFER_BASE_PATH } from "../path";

export const AuthNavRoute = (props: RouteProps) => {
  const isConnected = useObservable(authenticationManager.isConnected);
  const { path } = props;

  return isConnected ? (
    <PageWithNav withTitle={(path as string).includes(TRANSFER_BASE_PATH) ? false : path !== Path.JustifyTransaction}>
      <Route {...props} />
    </PageWithNav>
  ) : (
    <Redirect to={{ pathname: Path.Root }} />
  );
};
