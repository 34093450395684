import type { Amount } from "../../../core/amount/amount";
import type { RecurringTransferFrequency } from "../recurring-transfer";

export enum UpcomingTransferStatus {
  ACTIVATED = "ACTIVATED",
  DEACTIVATED = "DEACTIVATED",
  EXPIRED = "EXPIRED",
  DELETED = "DELETED",
}

export enum UpcomingTransferStatusColor {
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
  INFO = "info",
}

export const UPCOMING_TRANSFER_STATUSES: {
  [key in UpcomingTransferStatus]: {
    label: string;
    color?: UpcomingTransferStatusColor;
  };
} = {
  [UpcomingTransferStatus.ACTIVATED]: {
    label: "upcomingTransfers.status.activated",
    color: UpcomingTransferStatusColor.SUCCESS,
  },
  [UpcomingTransferStatus.DEACTIVATED]: {
    label: "upcomingTransfers.status.deactivated",
    color: UpcomingTransferStatusColor.WARNING,
  },
  [UpcomingTransferStatus.EXPIRED]: {
    label: "upcomingTransfers.status.expired",
    color: UpcomingTransferStatusColor.INFO,
  },
  [UpcomingTransferStatus.DELETED]: {
    label: "upcomingTransfers.status.deleted",
    color: UpcomingTransferStatusColor.ERROR,
  },
};

export interface UpcomingTransfer {
  id: string;
  nextExecutionDate: string;
  firstExecutionDate: string;
  endDate: string;
  frequency: RecurringTransferFrequency;
  customerInstructionOrderingParties: {
    debtor: {
      accountId: {
        value: string;
      };
    };
  };
  status: UpcomingTransferStatus;
  paymentTransaction: {
    paymentTransactionAmountInformation: {
      instructedAmount: Amount;
    };
    paymentTransactionParties: {
      creditor: {
        name: string;
      };
    };
    paymentTransactionDedicatedInformations: {
      remittanceInformation: {
        value: string;
      };
    };
  };
}
